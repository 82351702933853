import React, { useEffect, useState } from "react";
import "../CSS/Univers.css"; // Assurez-vous que ce fichier est bien lié
import './BackgroundVideo.css'; // Import the background video CSS
import Navbar from "./Navbar";

const Univers = () => {
    const cards = [
        {
            image: "/Uploads/Elise1722.webp",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/DSC00721.jpg",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/DSC01049.JPG",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/DSC06076.jpg",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/Elise1722.webp",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/DSC00721.jpg",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/DSC01049.JPG",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
        {
            image: "/Uploads/DSC06076.jpg",
            title: "Robe d'Halloween",
            description: "La robe d'Halloween est une robe de sorcière noire avec des motifs de citrouilles.",
        },
    ];

    const [visibleCards, setVisibleCards] = useState([]);

    useEffect(() => {
        const timer = setInterval(() => {
            setVisibleCards((prev) => {
                if (prev.length < cards.length) {
                    return [...prev, cards[prev.length]];
                } else {
                    clearInterval(timer);
                    return prev;
                }
            });
        }, 300); // 1 seconde pour chaque carte

        return () => clearInterval(timer); // Nettoyage de l'intervalle si le composant est démonté
    }, []);

    return (
        <>
            <Navbar />
            <div className="mt-5 container">
                <div className="content">
                    <div className="univers-container">
                        <h1 className="univers-title">Découvrez Nos Univers</h1>
                        <div className="container">
                            <div className="row">
                                {visibleCards.map((card, index) => (
                                    <div
                                        key={index}
                                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                                        style={{ animationDelay: `${index * 0.1}s` }} // Délai réduit entre les cartes
                                    >
                                        <div className="card-univers mb-5">
                                            <img
                                                src={card.image}
                                                alt={card.title}
                                                className="card-img-univers"
                                            />
                                            <div className="card-body-univers">
                                                <h3 className="card-title-univers">{card.title}</h3>
                                                <p className="card-description-univers">{card.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Univers;