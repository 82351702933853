import React from 'react';
import '../CSS/Wrap.css';
import { Link } from 'react-router-dom';


function Wrap() {
    return (
        <>
        <div className="wrap-container">
          <img src="/Uploads/Elise1722.webp" alt="Description" className="wrap-image" />
          <div className="wrap-text">
            <h1 className="wrap-name">ELISE CONVERS</h1>
            <p className="wrap-description">Je suis une Costumière Habilleuse située dans le Puy de Dome<br></br>Actuellement spécialisé dans la création de costumes sur mesure, j'ai comme ambition de......</p>
              <Link className="Morph_Nav_Btn wrap-button" to="/univers">Découvrez mon univers</Link>
          </div>
        </div>
        </>
      );
}

export default Wrap;
