import React from 'react';
import Navbar from "./Navbar";

const Register = () => {
    return (
        <>
            <Navbar></Navbar>
        <div className="mt-5 container">
            <div className="row justify-content-center">
                <div className="mt-5 col-md-6">
                    <div className="card mt-5 shadow-lg border-0">
                        <div className="card-header bg-dark text-white text-center">
                            <h3>Inscription</h3>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="form-group mb-3">
                                    <label htmlFor="name">Nom</label>
                                    <input type="text" className="form-control" id="name" placeholder="Entrez votre nom" required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email">Adresse e-mail</label>
                                    <input type="email" className="form-control" id="email" placeholder="Entrez votre e-mail" required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="password">Mot de passe</label>
                                    <input type="password" className="form-control" id="password" placeholder="Entrez votre mot de passe" required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
                                    <input type="password" className="form-control" id="confirmPassword" placeholder="Confirmez votre mot de passe" required />
                                </div>
                                <center>
                                    <button type="submit" className="btn btn-dark btn-block">S'inscrire</button>
                                </center>
                            </form>
                        </div>
                        <div className="card-footer text-center">
                            <a href="/" className="text-primary">Déjà un compte ? Connectez-vous</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Register;