import React from 'react';
import Navbar from './Navbar';
import './BackgroundVideo.css';

const Login = () => {
    return (
        <>
            <Navbar />
            <div className="mt-5 container">
                <div className="content">
                    <div className="row justify-content-center">
                        <div className="mt-5 col-md-6">
                            <div className="card mt-5 shadow-lg border-0">
                                <div className="card-header bg-dark text-white text-center">
                                    <h3>Connexion</h3>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group mb-3">
                                            <label htmlFor="email">Adresse e-mail</label>
                                            <input type="email" className="form-control" id="email" placeholder="Entrez votre e-mail" required />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label htmlFor="password">Mot de passe</label>
                                            <input type="password" className="form-control" id="password" placeholder="Entrez votre mot de passe" required />
                                        </div>
                                        <div className="form-group form-check mb-3">
                                            <input type="checkbox" className="form-check-input" id="rememberMe" />
                                            <label className="form-check-label" htmlFor="rememberMe">Se souvenir de moi</label>
                                        </div>
                                        <center><button type="submit" className="btn btn-dark btn-block">Se connecter</button></center>
                                    </form>
                                </div>
                                <div className="card-footer text-center">
                                    <a href="/" className="text-primary">Mot de passe oublié?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;