import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Wrap from './Components/Wrap';
import Login from './Components/Login';
import Register from './Components/Register';
import FeedBack from './Components/FeedBack';
import Univers from './Components/Univers';
import './NotFound.css';
import './Components/BackgroundVideo.css';

function NotFound() {
    return (
        <div className="not-found-container">
            <h1 className="not-found-title">404</h1>
            <p className="not-found-text">Oops! La page que vous cherchez n'a pas été trouvée..</p>
            <a href="/" className="not-found-link">Retour</a>
            <div className="not-found-image">
                <img src="https://www.icegif.com/wp-content/uploads/2023/10/icegif-441.gif" alt="404 Not Found" />
            </div>
        </div>
    );
}

const PersistentVideo = () => {
    return (
        <video autoPlay loop muted playsInline className="background-video">
            <source src="/Uploads/BackVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

function AppContent() {
    const location = useLocation();

    return (
        <>
            {location.pathname !== '/' && <PersistentVideo />}
            <Routes>
                <Route path="/" element={<Wrap />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/feedback" element={<FeedBack />} />
                <Route path="/univers" element={<Univers />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;