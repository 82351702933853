import React from 'react';
import '../CSS/FeedBack.css'; // Fichier CSS pour les styles
import './BackgroundVideo.css'; // Import the background video CSS
import Navbar from './Navbar';

const reviews = [
    { name: "Alice", rating: 5, review: "Service excellent, je recommande vivement !" },
    { name: "Bob", rating: 4, review: "Très bon produit, quelques petites améliorations possibles." },
    { name: "Charlie", rating: 3, review: "Correct mais l'expérience pourrait être meilleure." },
    { name: "Diane", rating: 5, review: "Exceptionnel, tout était parfait !" },
    { name: "Eve", rating: 2, review: "Décevant, je m'attendais à mieux." },
    { name: "Frank", rating: 4, review: "Bon rapport qualité-prix, satisfait de mon achat." },
    { name: "Grace", rating: 5, review: "Incroyable, je suis tellement contente !" },
    { name: "Hank", rating: 3, review: "Rien de spécial, c'est juste correct." },
    { name: "Ivy", rating: 5, review: "Fantastique, je n'ai rien à redire !" },
    { name: "Jack", rating: 4, review: "Très bien, mais peut encore être amélioré." },
];

const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
        <i
            key={index}
            className={`fas fa-star ${index < rating ? 'text-warning' : 'text-muted'}`}
        ></i>
    ));
};

const FeedBack = () => {
    return (
        <>
            <Navbar />
            <div className="mt-5 container">
                <div className="content slide-in-left">
                    <h2 className="text-center mb-4">Avis Clients</h2>
                    <p className="text-center mb-4">
                        Sur cette page, découvrez les avis de nos clients. Chaque commentaire reflète leur expérience avec nos
                        services et produits,
                        afin de vous guider dans vos choix. Merci à nos clients pour leur confiance et leurs retours précieux !
                    </p>
                    <div className="row">
                        {reviews.map((review, index) => (
                            <div key={index} className="col-sm-3 mb-4">
                                <div className="card h-100 feedback-card shadow-lg border-0">
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title text-primary">{review.name}</h5>
                                        <div className="mb-2">{renderStars(review.rating)}</div>
                                        <p className="card-text flex-grow-1">{review.review}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <button className={"btn btn-dark "}>Charger plus d'avis</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeedBack;