import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg custom-navbar">
            <div className="container">
                <Link className="navbar-brand custom-brand" to="/">Convers</Link>
                <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i> {/* Icône hamburger */}
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link custom-link" to="/">Accueil</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link custom-link" to="/univers">Mes créations</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link custom-link" to="/feedback">Avis Clients</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link custom-link" to="/contact">Me contacter</Link>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;